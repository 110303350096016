.table.dataTable td, .table.dataTable p , .table.dataTable a, .table.dataTable i
{
    padding: 2px;
    margin-bottom: 2px;
}

.focusedRowDT
{
    font-weight: bold;
    text-shadow: 1px 1px 0px #EEE;
    color: #111;
}
