@import url('https://fonts.googleapis.com/css?family=Nunito');

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


// Variables
@import 'variables';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";

@import "~toastr/toastr";

@import "custom";
